import { connect } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { logoutRoutine } from 'redux-state/ducks/auth';
import AuthGuard from './auth-guard.component';

const mapStateToProps = ({ app, firebase }) => ({
  firstTabTarget: app?.config?.tabs?.[0]?.children?.[0]?.target ?? app?.config?.tabs?.[0]?.target,
  firstTabTargetId: app?.config?.tabs?.[0]?.children?.[0]?.targetId ?? app?.config?.tabs?.[0]?.targetId,
  isAuthenticated: !isEmpty(firebase?.auth) ?? false,
  hasSubscription: !!app.userSubscriptions?.length ?? false,
});

const mapDispatchToProps = {
  logoutAction: logoutRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
